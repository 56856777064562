.textbox-label {
    
}
input.textbox-input {
    border : 1px solid #bbb;
    border-radius: 3px;
    outline : 1px solid transparent;
    padding : 6px 8px;
}

.input-ta {
    
}

input.input-text {
    border : 1px solid #bbb;
    border-radius: 2px;
    padding : 1px 2px;
}







.b-wrapper {
    flex-wrap: nowrap;
    min-height: 100vh;
    position : relative;
}


.b-wrapper::before {
    content: "";
    display : block;
    height : 65px;
}

.b-login-wp {
    flex-wrap: nowrap;
    min-height: 100vh;
    position : relative;
}

.b-login-wp-inner {
    flex : 1;
    max-width: 500px;
    width : 100%;
    padding-left : 20px;
    padding-right : 20px;
}

.b-loadingcover {
    display : flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:10px;
    position : fixed;
    top : 0;
    left: 0;
    height : 100%;
    width : 100%;
    background-color:rgba(255,255,255,0.4);
    transition : background-color 0.2s;
    z-index:99;
}


.b-header-wp {

    position : fixed;
    top : 0;
    left : 0;
    width : 100%;
    height : 65px;
    padding : 0 15px;
    background-color : #313131;
    color : #fff;
    transition: height 0.15s;
    z-index : 3;
}
.b-header-wp.small {
    height : 50px;
}


.mainmenu-icn {
    width : 20px;
    height : 18px;
}
.mainmenu-icn > span {
    width : 100%;
    height : 2px;
    border-radius : 2px;
    background-color : #fff;
}

.b-mainmenu-mob {
    /* position : fixed;
    right: 0;
    top : 55px; */
    width : 100%;
    height : 100vh;
    background-color : rgba(0,0,0,0.8);
    color : #fff;
    padding : 0 15px;
    /* display : none; */
}
.b-mainmenu-mob.open {
    /* display : block; */
}

.b-mainmenu-mob-info {
    width : 100%;
    height : 55px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap : 12px;
    font-size : 13px;
    border-bottom : 1px dashed #636363;
}

.b-mainmenu-mob-list {
    display: flex;
    flex-direction: column;
    align-items: start;
}
.b-mainmenu-mob-item {
    display : flex;
    align-items: center;
    justify-content: start;
    font-size : 14px;
    line-height: 1;
    padding : 12px 0;
    width : 100%;
    border-bottom : 1px solid #454545;
}
.b-mainmenu-mob-item:first-child {
    border-top : 1px solid #454545;
}
.b-mainmenu-mob-item-link {
    display : flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.b-mainmenu-mob-item-link::after {
    content:"";
    display: inline-block;
  width: 7px;
  height: 9.9px;
  background: #ffffff;
  clip-path: polygon(0 50%, 29.3% 50%, 29.3% 0, 100% 50%, 29.3% 100%, 29.3% 50%, 0 50%);
}

.b-main-wp {
    flex : 1;
    max-width: 500px;
    width : 100%;
    padding-left : 20px;
    padding-right : 20px;
}

.b-footer-wp {
    width : 100%;
    padding : 5px 0;
    margin-top : 40px;
    background-color : #ebebeb;
}
.b-footer-wp .footer {
    color : #8f8f8f;
    font-size : 10px;
}


.b-guide {
    font-size : 11px;
    display : flex;
}
.b-guide > li:not(:first-child) {
    padding-left : 5px;
}
.b-guide > li:not(:first-child)::before {
    content : ">";
    display : inline-block;
    padding-right : 5px;
}


.b-picareaguide {
    border : 1px solid #bbb;
    border-radius : 3px;
    background-color: #e5e5e5;
    padding : 8px;
}
.picareaguidetitle {
    font-size : 12px;
}

.b-camera {
    width : 100%;
    min-height : 200px;
    border : 1px dashed #a7a7a7;
    border-radius: 6px;
    display : flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.b-camera-re {
    width : 100%;
    height : 40px;
    border-radius: 2px;
    display : flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color : #656565;
    color : #fff;
}

.b-camera-button {
    width : 110px;
    height : 110px;
    background-color : rgba(32, 87, 138, 0.8);
    border-radius: 100%;
}

.b-cleanlist {
    display : flex;
    flex-direction: column;
    align-items: start;
    gap : 10px;
}
.b-cleanlist-item {
    display : flex;
    width : 100%;
}
.b-cleanlist-item-link {
    display : flex;
    align-items: center;
    justify-content: space-between;
    gap : 3px;
    height : 64px;
    width: 100%;
    padding : 2px 12px;
    background-color: #dbdbdb;
    border-radius: 2px;
}
.b-cleanlist-item.done .b-cleanlist-item-link {
    background-color: #a5e0c3;
}
.b-cleanlist-item-link::after {
    content:"";
    display: inline-block;
  width: 7px;
  height: 28.3px;
  background: #4e4e4e;
  clip-path: polygon(0 50%, -102% 50%, -102% 0, 100% 50%, -102% 100%, -102% 50%, 0 50%);
}

.text-16px {
    font-size : 16px;
}
.text-15px {
    font-size : 15px;
}
.text-14px {
    font-size : 14px;
}
.text-12px {
    font-size : 12px;
}
.text-10px {
    font-size : 10px;
}

.btn-1 {
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width : 100%;
    min-height : 40px;
    padding : 10px 8px ;
    border-radius: 2px;
}

.btn-style-blue-1 {
    color : #fff;
    background-color: #3c6ba0;
}

.ml-10px-m {
    margin-left : -10px;
}