body {
    background-color : #efefef;
}

.b-mnt-drawer {
    
}

.b-mnt-systitle {
    
    line-height : 1;
    display : flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size : 14px;
    letter-spacing: 0.05em;
    padding-right: 15px;
    height : 90px;
    border-bottom : 1px solid #475a62;
}

.b-mnt-menu-list {
    display : flex;
    flex-direction: column;
    align-items: start;

    width: 100%;
}
.b-mnt-menu-item {
    font-size : 14px;
    display : flex;
    justify-content: space-between;
    align-items: center;
    width : 100%;
    border-bottom : 1px solid #475a62;
    
    
}

.b-mnt-menu-link {
    display : flex;
    justify-content: space-between;
    align-items: center;
    width : 100%;
    height : 45px;
    padding : 0 12px;
    transition: background-color 0.2s;
}



.b-mnt-menu-link::after {
    content:"";
    display: inline-block;
    width: 7px;
    height: 9.9px;
    background: #ffffff;
    clip-path: polygon(0 50%, 29.3% 50%, 29.3% 0, 100% 50%, 29.3% 100%, 29.3% 50%, 0 50%);
}

.b-mnt-menu-link:hover {
    background-color : #3d525c;
}

.mnt-mainmenu-icn {
    display : flex;
    flex-direction: column;
    justify-content: space-between;

    width : 16px;
    height : 14px;
}
.mnt-mainmenu-icn > span {
    width : 100%;
    height : 2px;
    border-radius : 2px;
    background-color : #4a4a4a;
}

.b-mnt-header {
    display : flex;
    justify-content: space-between;
    align-items: center;
    width : 100%;
    height : 45px;
    padding : 0 20px;
}

.b-mnt-main-wrap {
    padding : 0 20px 20px;
}

.b-mnt-login-hdr {

}

.b-mnt-login-wrap {
    width : 280px;
    padding-top : 45px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border : 1px solid #bbb;
    border-radius : 4px;
}



.mnt-pagetitle {
    padding-top : 15px;
    padding-bottom : 15px;
    margin-bottom : 20px;
    font-weight : bold;
    font-size : 16px;
    border-bottom : 1px dashed #c9c9c9;
    letter-spacing: 0.02em;
}

.b-mnt-search {
    display : flex;
    flex-direction: column;
    gap : 6px;
    padding : 15px;
    border : 1px solid #d7d7d7;
    position : relative;
    border-radius: 2px;
}
.mnt-search-title {
    display : block;
    font-size : 12px;
    background-color : #efefef;
    color : #bdbdbd;
    position : absolute;
    left : 10px;
    top : -10px;
    padding : 0 5px;
    letter-spacing: 0.05em;

}

.btn-mnt-01,
.btn-mnt-02 {
    display : flex;
    align-items: center;
    justify-content: center;
    font-size : 12px;
    letter-spacing: 0.1em;
    line-height:1;
    color : #fff;
    min-width : 95px;
    min-height : 26px;
    padding : 4px 15px;
    border-radius : 2px;
    transition : background-color 0.1s;
}
.btn-mnt-01 {
    background-color : #31589f;
}
.btn-mnt-01:hover {
    background-color : #4467a8;
}   
.btn-mnt-02 {
    background-color : #585858;
}
.btn-mnt-02:hover {
    background-color : #6d6d6d;
}  


.btn-mnt-login {
    width : flex;
    align-items: center;
    justify-content: center;
    background-color : #2b4bb4;
    color : #fff;
    width : 180px;
    height : 30px;
    border-radius : 60px;
    font-size : 12px;
    transition: background-color 0.2s;
}
.btn-mnt-login:hover {
    background-color : #3558ca;
}



/******* 清掃状態一覧 *******/

.b-mnt-pics-latest-floor {
    display: flex;
    align-items: center;
    justify-content: start;
    gap : 5px;
    font-weight : 500;
    line-height:1;
}

.b-mnt-pics-latest-wrap {
    display : flex;
    flex-wrap : wrap;
    gap : 15px 10px;
}
.b-mnt-pics-latest-item {
    display: flex;
    flex-direction: column;
    flex-grow : 1;
    background-color : #353535;
    height : 350px;
    padding : 10px 10px 16px;
    border-radius: 4px;
    /* max-width : 260px; */
}
.b-mnt-pics-latest-item img {
   
}
.mnt-pics-latest-title {
    text-align: center;
    height : 42px;
    color: #fff;
}

.mnt-pics-latest-picbutton {
    display: flex;
    height : calc(100% - 42px);
    width : 100%;
}

.mnt-pics-latest-pic {
    flex-grow: 1;
    object-fit: contain;
    height : 100% ;
    width : 100%;

}
